import React from 'react'
import './PrivacyPolicy.css'
import Navbar from './Navbar'
import Line from './Line'
export const PrivacyPolicy = () => {
  return (
    <div>
<Navbar/>
<Line/>
    </div>
  )
}
export default PrivacyPolicy