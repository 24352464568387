import React from 'react'
import './Footer.css'
import { Link} from 'react-router-dom'

export const Footer = () => {
  return (
    <footer>
      <div className="footer-content">
        <div className="footer-links">
          <Link to={'/privacypolicy'}><a>Privacy Policy</a></Link>
          <Link to={'/cookiepolicy'}><a>Cookies Policy</a></Link>
        </div>
      </div>
    </footer>
  )
}
export default Footer