import React from 'react'
import './Line.css'
const Line = () => {
  return (
    <div className='div'>
    
    </div>
  )
}

export default Line
