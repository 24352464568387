
import Navbar from './Navbar'
import Line from './Line'
import React, { useState } from 'react';
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import './Signup.css'
import { useNavigate,Link } from 'react-router-dom';
export const Signup = () => {
    const navigate=useNavigate()
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const auth = getAuth();
    const handleSignup = () => {
        createUserWithEmailAndPassword(auth, email, password)
          .then((userCredential) => {
            const user = userCredential.user;
            navigate('/');
          })
          .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            setErrorMessage(errorMessage);
          });
      };
  return (
    <div>
        <Navbar/>
        <Line/>
        <div className="signup-container">
      <div className="signup-box">
        <h2>Sign up manually</h2>
        <div>
          <label>Email:</label>
          <input 
            type="email" 
            value={email} 
            onChange={(e) => setEmail(e.target.value)} 
          />
        </div>
        <div>
          <label>Password:</label>
          <input 
            type="password" 
            value={password} 
            onChange={(e) => setPassword(e.target.value)} 
          />
        </div>
        {errorMessage && <p>{errorMessage}</p>}
        <button onClick={handleSignup}>SIGNUP</button>
      </div>
    </div>
    </div>
  )
}
