import React from "react";
import Navbar from "./Components/Navbar";
import Line from "./Components/Line";
import Auth from "./Components/Auth";
import Maps from "./Components/Maps";
import { Signup } from "./Components/Signup";
import {BrowserRouter as Router ,Routes,Route} from 'react-router-dom'
import  PrivacyPolicy from "./Components/PrivacyPolicy";
import CookiesPolicy  from "./Components/CookiesPolicy";
function App() {
  return (
    <div>
      
<Router>
  <Routes>
    <Route path='/' element={<Maps/>}/>
    <Route path='auth' element={<Auth/>}/>
    <Route path='signup' element={<Signup/>}/>
    <Route path='privacypolicy' element={<PrivacyPolicy/>}/>
    <Route path='cookiepolicy' element={<CookiesPolicy/>}/>
  </Routes>
  </Router>   
</div>
  );
}

export default App;
