import React, { useState,useEffect } from 'react';
import { GoogleMap, useLoadScript, Marker, StandaloneSearchBox,InfoWindow  } from "@react-google-maps/api";
import './Maps.css';
import Navbar from './Navbar';
import Line from './Line';
import { Link , useNavigate} from 'react-router-dom'
import {auth,db} from './Firebase'
import { addDoc, collection,getDocs,onSnapshot, query } from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import Footer from './Footer';


const Maps = () => {
  const navigate = useNavigate();
  const [locations, setLocations] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [searchBox, setSearchBox] = useState(null);
  const [marker, setMarker] = useState(null);
  const [center, setCenter] = useState({ lat: 51.1657, lng: 10.4515});
  const [showReportForm, setShowReportForm] = useState(false);
  const [location, setLocation] = useState('');
  const [date, setDate] = useState('');
  const [description, setDescription] = useState('');
  const [imageFile, setImageFile] = useState(null);
  const [zoom, setZoom] = useState(6);
  const [selectedSearchResult, setSelectedSearchResult] = useState(null);

  

  const handleImageUpload = async () => {
    if (imageFile) {
      const storage = getStorage();
      const storageRef = ref(storage, `images/${imageFile.name}`);
      await uploadBytes(storageRef, imageFile);
      return getDownloadURL(storageRef);
    }
    return null;
  };
  
  const handleMarkerClick = (location) => {
    if (location) {
      setLocation(location.location || '');
      setDate(location.date || '');
    }
  };
  
   
  
// useEffect(() => {
//   const unsubscribe = onSnapshot(
//     query(collection(db, 'reports')),
//     (snapshot) => {
//       const newLocations = snapshot.docs.map((doc) => doc.data());
//       setLocations(newLocations);
//     }
//   );

//   return () => unsubscribe();
// }, []);
useEffect(() => {
  const fetchData = async () => {
    const querySnapshot = await getDocs(collection(db, 'reports'));
    const retrievedLocations = querySnapshot.docs.map(doc => {
      return {
        ...doc.data(), // this will spread all the data fields, including imageUrl if it exists
      }
    });
    setLocations(retrievedLocations);
  }
  fetchData();
}, []);



const handleFormSubmit = async () => {
  if (auth.currentUser) {
    try {
      const imageUrl = await handleImageUpload();
      await addDoc(collection(db, 'reports'), {
        location: location,
        lat: marker.lat,
        lng: marker.lng,
        date: date,
        description: description,
        userId: auth.currentUser.uid,
        imageUrl: imageUrl // Save the URL of the image in Firestore
      });
      setShowReportForm(false);
      alert("Report Submitted"); 
    } catch (error) {
      console.error("Error adding document: ", error);
    }
  } else {
    navigate('/auth');
  }
};




  const { isLoaded } = useLoadScript ({
    googleMapsApiKey: 'AIzaSyBsLp6Y54LRqIOAkWIl6ebcLXXKC163pH4',
    libraries: ['places']
  });

  if (!isLoaded) return <div>Loading...</div>;
  

  return (
    <div className='mainDivMapSection'>
      <Navbar/>
      <Line/>
      <div style={{position: 'relative'}} className='navbarMainDiv'>
        <GoogleMap
          zoom={zoom}
          center={center} 
          mapContainerClassName='map-container'
        >
  {locations.map((location, index) => (
  <Marker
    key={index}
    position={{ lat: location.lat, lng: location.lng }}
    onClick={() => {
      setSelectedLocation(location);
     
      handleMarkerClick(location);
    }} 
  />
))}



{selectedLocation && (
  <InfoWindow
    position={{ lat: selectedLocation.lat, lng: selectedLocation.lng }}
    onCloseClick={() => setSelectedLocation(null)}
   
  >
    <div >
      
      <h4>{selectedLocation.location || "Location not available"}</h4>
      <p>{selectedLocation.date}</p>
      {selectedLocation.imageUrl && <img src={selectedLocation.imageUrl} alt="Reported Image" style={{width: '100px', height: '100px'}} />} 
      
    </div>
  </InfoWindow>
)}


{/* {marker && <Marker position={marker}  />} */}
          <div className="search-box">
            <StandaloneSearchBox
              onLoad={ref => setSearchBox(ref)}
              onPlacesChanged={() => {
                if (searchBox) {
                  const place = searchBox.getPlaces()[0];
                  if (place && place.geometry && place.geometry.location) {
                    const newMarker = {
                      lat: place.geometry.location.lat(),
                      lng: place.geometry.location.lng(),
                      location: place.name,
                      date: new Date().toISOString().split('T')[0]

                    };
                    setMarker(newMarker);
                    setCenter(newMarker);
                    setSelectedSearchResult(newMarker);
                    setZoom(12);
                  }
                }
              }}
            >
              <input type="text" placeholder="Search Location" />
            </StandaloneSearchBox>
          </div>
          {marker && <Marker position={marker} onClick={() => {
      // setSelectedLocation(selectedSearchResult);
      handleMarkerClick(selectedSearchResult);
    }} />}
        </GoogleMap>
        {!showReportForm && (
        <button 
          style={{position: 'absolute', right: '70px', bottom: '30px', height: '40px', width: '140px', borderRadius: '20px', fontWeight: 'bold'}}
          onClick={() => setShowReportForm(true)}
        >
          Submit Report
        </button>)}
        {showReportForm && (
        <div style={{position: 'absolute', right: '10px', bottom: '60px', width: '200px', background: 'white' ,display: 'flex', flexDirection: 'column'}}>
         <button style={{ alignSelf: 'flex-end',marginBottom:'5px' }} onClick={() => setShowReportForm(false)}>X</button>
        <input type="text" placeholder="Location" value={location} onChange={(e) => setLocation(e.target.value)} />
       <input type="date" placeholder="Date" value={date} onChange={(e) => setDate(e.target.value)} />
       <textarea placeholder="Description" value={description} onChange={(e) => setDescription(e.target.value)}></textarea>
       <input type="file" onChange={(e) => setImageFile(e.target.files[0])} />

       <button type="submit" onClick={handleFormSubmit}>Submit</button>
        </div>
      )}
      </div>

      <Footer/> 
    </div>
  );
};

export default Maps;
