import React from 'react'
import './Auth.css'
import { auth } from './Firebase';
import 'firebase/auth';
import { useState } from 'react';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { TwitterAuthProvider,signInWithPopup,GoogleAuthProvider } from "firebase/auth";
import Navbar from './Navbar';
import Line from './Line';
import { getAuth, signOut } from "firebase/auth";
import { useNavigate,Link } from 'react-router-dom';
import  Footer  from './Footer';
const Auth = () => {
const navigate=useNavigate()

  const func=()=>{
    const auth = getAuth();
    signOut(auth).then(() => {
      // Sign-out successful
       
      navigate('/');
      console.log('logged out')
    }).catch((error) => {
      // An error happened.
    });
  }


    const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  // Function to handle login
  const handleLogin = () => {
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Successful login
      
        navigate('/');
        const user = userCredential.user;
        console.log('User is logged in:', user);
      })
      .catch((error) => {
        // Failed login
        console.log('Error:', error);
      });
  };
      

  
const signInWithTwitter=()=>{
const provider = new TwitterAuthProvider();
signInWithPopup(auth,provider)
.then((re)=>{
  navigate('/');
  
  console.log(re);
})
.catch((err)=>{
  console.log(err)
})

}


const signInWithGoogle=()=>{
const provider=new GoogleAuthProvider();
signInWithPopup(auth,provider)
.then((re)=>{
  
  navigate('/');
  console.log(re);

})
.catch((err)=>{
  console.log(err)
})

}

return (
    <div>
      <Navbar/>
      <Line/>
    <div className='AuthMainDiv'>
    <div className='divsub1'>
    <div class="social-buttons">
        <div class="btn facebook">
            <img src="/img/fb1.png" alt="Facebook Icon"/> Login with Facebook
        </div>
        <div class="btn twitter" onClick={signInWithTwitter}>
            <img src="/img/t1.png" alt="Twitter Icon" /> Login with Twitter
        </div>
        <div class="btn google" onClick={signInWithGoogle}>
            <img src="/img/g2.png" alt="Google Icon"/> Login with Google+
        </div>
    </div>
    </div>
    <div className='divsub2'>
    <div class="separator">
        <div class="separator-line"></div>
        <div class="separator-circle">OR</div>
        <div class="separator-line"></div>
    </div>
    </div>
    <div className='divsub3'>

    <div className="login-container">
      <h2>Sign in manually</h2>
      <div className="input-group">
        <label>Username or email</label>
        <input type="text" value={email} onChange={(e) => setEmail(e.target.value)} />
      </div>
      <div className="input-group">
        <label>Password</label>
        <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
      </div>
      <div className="options-group">
        <input type="checkbox" id="remember" />
        <label htmlFor="remember">Remember me</label>
      </div>
      <button className="login-button" onClick={handleLogin}>LOGIN</button>
      <div className="links-group">
        <Link to={'/signup'}><a href="#">Register now</a></Link>
        <span>|</span>
        <a href="#">Forgot password?</a>
         {/* <button onClick={func}>logout</button>  */}
      </div>
    </div>



        
    </div>

    </div>
 <Footer/> 
    </div>
  )
}

export default Auth



