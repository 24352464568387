import React, { useState ,useEffect} from 'react'
import './Navbar.css'
import { Link, useLocation } from 'react-router-dom'
import { getAuth, onAuthStateChanged,signOut } from 'firebase/auth';
import {useNavigate} from 'react-router-dom'
const Navbar = () => {
  const navigate=useNavigate()
  const func=()=>{
    const auth = getAuth();
    signOut(auth).then(() => {
      // Sign-out successful
       
      navigate('/');
      console.log('logged out')
    }).catch((error) => {
      // An error happened.
    });
  }
  const location = useLocation();
  const currentPath = location.pathname;

  const [user, setUser] = useState(false);

  useEffect(() => {
    // Initialize Firebase and get the authentication instance
    const auth = getAuth();

    // Listen for changes in the user's authentication state
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in
        setUser(true);
      } else {
        // User is signed out
        setUser(null);
      }
    });

    // Clean up the listener when the component unmounts
    return () => unsubscribe();
  }, []);

  return (
    <div className='MainDiv'>
      <div className='div1'>
        <img className='imgname' src='/img/image 1.png'/>
        <p className='p1'>reportabedbug.com</p>
      </div>
      <div className='div2'>
        <Link to={'/'} style={{ textDecoration: 'none' }}>
          <h3 
            className='head1' 
            style={{ color: currentPath === '/' ? 'red' : 'black' }} 
          >
            View Map
          </h3>
        </Link>
        {user?<h3 className='head2' onClick={func}>Sign Out</h3>:<Link to={'/auth'} style={{ textDecoration: 'none' }}>
          <h3 
            className='head2' 
            style={{ color: currentPath === '/auth' ? 'red' : 'black' }} 
          >
            Sign In
          </h3>
        </Link>}
        
      </div>
    </div>
  )
}

export default Navbar
