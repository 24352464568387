import React from 'react'
import Navbar from './Navbar'
import Line from './Line'
import './CookiesPolicy.css'
export const CookiesPolicy = () => {
  return (
    <div>
<Navbar/>
<Line/>
    </div>
  )
}
export default CookiesPolicy